<script setup lang="ts">
import { NuxtLink } from "#components";

const props = withDefaults(
  defineProps<{
    item: BoardItem;
    titleTag?: string | Component;
    interactive?: boolean;
  }>(),
  {
    titleTag: "h2",
    interactive: true,
  }
);

const images = computed(() =>
  Array.from({ length: 4 }).map(
    (_, index) => props.item.items?.[index]?.images_id
  )
);

const localePath = useLocalePath();
const link = computed(() =>
  localePath({ name: "boards-id", params: { id: props.item.id } })
);

const { currentPalette } = useTheme();
</script>

<template>
  <div class="board-grid-item select-none">
    <div class="board-grid-item__wrapper flex flex-col items-stretch gap-2">
      <div class="board-grid-item__images grid grid-cols-3 gap-2">
        <figure
          v-for="(image, index) in images"
          :class="[
            'board-grid-item__image',
            'relative aspect-square',
            'bg-grey-100',
            {
              'board-grid-item__image--main col-span-3': index === 0,
            },
          ]"
        >
          <component
            :is="interactive ? NuxtLink : 'div'"
            :to="interactive ? link : undefined"
            :title="props.item.title"
            class="block absolute inset-0"
          >
            <NuxtPicture
              v-if="image?.file"
              provider="directus"
              :src="image.file?.id"
              :width="index === 0 ? 310 : 98"
              :height="index === 0 ? 310 : 98"
              class="absolute inset-0 w-full h-full object-cover"
              :img-attrs="{
                class: 'absolute inset-0 w-full h-full object-cover',
                draggable: false,
              }"
              :alt="image.file.description || image.caption || props.item.title"
              :sizes="
                index === 0
                  ? '290px xs:353px sm:576px md:692px lg:226px xl:310px xxl:430px'
                  : '30vw xs:118px sm:192px md:230px lg:75px xl:103px xxl:138px'
              "
              :class="[currentPalette.bg]"
              quality="75"
            />
          </component>

          <figcaption class="sr-only">
            {{ image?.file?.description || image?.caption || props.item.title }}
          </figcaption>
        </figure>
      </div>

      <div class="board-grid-item__content">
        <component
          :is="props.titleTag"
          class="board-grid-item__title my-[0.3125rem] text-black font-medium text-sm leading-[1.125rem]"
        >
          <component
            :is="interactive ? NuxtLink : 'div'"
            :to="interactive ? link : undefined"
          >
            <span>{{ props.item.title || "???" }}</span>
          </component>
        </component>
        <p
          class="board-grid-item__subtitle text-grey-700 text-sm leading-[0.875rem]"
        >
          <span>{{ props.item.items?.length || 0 }} inspiracje</span>
        </p>
      </div>
    </div>
  </div>
</template>
